<template>
  <!-- 出入库日志 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1 wtl_btnList" ref="btnList">
      <div class="btnList1 wtl_btnList1">
        <!-- <el-button class="itemBtn btnColor" @click="myModel"
          >列表设置</el-button> -->
        <el-dropdown @command="handleCommand_dc">
          <el-button class="dropdownBtn">
            导出
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="dc">导出</el-dropdown-item>
            <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
            <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="singleinpbtn wtl_queryBoxNew">
        <el-button class="seniorbut" @click="queryBtn_ok" icon="el-icon-search"
          >查询</el-button
        >
        <el-tooltip content="查询项恢复初始状态" placement="top">
          <div class="condition" @click="queryBtn_cz">清除</div>
        </el-tooltip>
        <el-tooltip content="查询更多条件" placement="top">
          <div class="condition" @click="more">{{ MoreConditions }}</div>
        </el-tooltip>
      </div>
    </div>
    <!-- 查询条件框 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <div class="singleinp wtlTimeBox">
            <div class="singleinpleft wtlTimeLabel">操作时间:</div>
            <div class="wtl_timeStyle">
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.startOperationTime"
                type="date"
                placeholder="选择日期"
                @change="queryBtn_ok"
              ></el-date-picker>
              <div>至</div>
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.endOperationTime"
                type="date"
                placeholder="选择日期"
                @change="queryBtn_ok"
              ></el-date-picker>
            </div>
          </div>

          <div class="singleinp">
            <div class="singleinpleft">打包批次号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="numbers"
                placeholder="请输入内容"
                clearable
                @keyup.enter.native="queryBtn_ok"
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">合包单号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.billClosePackageNumber"
                placeholder="请输入内容"
                clearable
                @keyup.enter.native="queryBtn_ok"
              ></el-input>
            </div>
          </div>

          <div class="singleinp">
            <div class="singleinpleft">操作:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.operationType"
                filterable
                placeholder="请选择"
                clearable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="(item, index) in orderTypeList"
                  :key="index"
                  :label="item.showName"
                  :value="item.statusVal"
                ></el-option>
              </el-select>
            </div>
          </div>

          <div class="singleinp">
            <div class="singleinpleft">出库仓库:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.outStorageId"
                filterable
                placeholder="请选择"
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in storageArr"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">入库仓库:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.inStorageId"
                filterable
                placeholder="请选择"
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in storageArr"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </el-form>

    <!-- 表格 -->
    <div class="tableBox" ref="tableWrapper">
      <el-table
        :height="tableHeigth"
        ref="mytable"
        :data="tableData"
        :row-class-name="tableRowClassName"
        highlight-current-row
        border
        :header-cell-style="{ background: '#f0f0f0' }"
        @row-click="rowclick"
        show-summary
        :summary-method="getSummaries"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          fixed="left"
          label="序号"
          type="index"
          width="60"
        ></el-table-column>
        <el-table-column
          fixed="left"
          type="selection"
          width="60"
        ></el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            :label="item.name"
            :prop="item.field"
            :min-width="item.width"
            sortable
            show-overflow-tooltip
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          ></el-table-column>
        </template>
      </el-table>
    </div>
    <!-- //排序 -->
    <div>
      <!-- 自定义表头排序 -->
      <sorttable
        :show_sortTableHeard="show_sortTableHeard"
        :allCheck="allCheck"
        :sortName="sortName"
        :tabelHeadeTitle="tabelHeadeTitle"
        :originalTabelHeadeTitle="originalTabelHeadeTitle"
        :selectLength="selectLength"
        @cancelSortHeard="cancelSortHeard"
        @confirmOk="confirmOk"
        @checktab="checktab"
        @checkAll="checkAll"
        @closeDias="closeDias"
      ></sorttable>
    </div>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import sorttable from "@/components/sortTemplate.vue";
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";

// import { axios_file } from "@/assets/js/http_axios";
export default {
  inject: ["reload"],
  components: {
    // mytables,
    draggable,
    sorttable,
    paging,
  },
  data() {
    return {
      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      queryData: {
        billClosePackageNumber: "",
        startOperationTime: "",
        endOperationTime: "",
        inStorageId: "",
        outStorageId: "",
        operationType: "",
      },
      isSensitiveList: [
        {
          val: 0,
          name: "非敏感",
        },
        {
          val: 1,
          name: "敏感",
        },
      ],
      caiGouFormData: {
        buyNumber: "",
        dgOrderNumber: "",
      },
      // ======公用组件==========
      pageNum: 1, //页码
      total: 0,
      sizeList: [10, 20, 50, 100, 200],
      size: 200, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "出入库批次号",
          field: "number",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "操作",
          field: "operationTypeShow",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "打包批次号",
          field: "billPackNumber",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "合包单号",
          field: "billClosePackageNumber",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "出库仓库",
          field: "outStorageName",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "入库仓库",
          field: "inStorageName",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "业务单据类型",
          field: "billPackTypeShow",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "备注",
          field: "comment",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "操作时间",
          field: "operationTime",
          width: "165",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "BillPackStockRecord", //页面标识

      writeoffStatusList: [], //核销状态
      orderTypeList: [], //代购状态
      orderFormList: [], //归属代理商
      currentSelectData: [], //选中的数据
      ids: [], //选中的id数据
      ImgNames: [],
      zijingList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      memberList: [],

      dialogImageUrl: "", //大图
      showBigImg: false,
      genJinShow: false,
      genjinTitle: "新建跟进",
      openType: "",
      addFormData: {
        dgOrderNumber: "",
        endPrice: "",
        itemDescription: "",
        memberId: "",
        pics: [],
        quantity: "",
        startPrice: "",
        agentName: "",
        memberName: "",
      },
      fileListImg: [],
      rules: {
        memberId: [
          { required: true, message: "请选择", trigger: ["change", "blur"] },
        ],
        itemDescription: [
          { required: true, message: "请输入", trigger: ["change", "blur"] },
        ],
        quantity: [
          { required: true, message: "请输入", trigger: ["change", "blur"] },
        ],
      },
      storageArr: [],
      numbers: "", //打包批次号字符串
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.myTableHeard = this.tabelHeadeTitle;
    this.getGroupFindFieldSort(); //获取用户自定义表头

    this.queryData.endOperationTime = tools.getCurrentDate(); //今天的时间;
    this.queryData.startOperationTime = tools.getFlexDate(-10); //10天前的时间

    this.getStatusValList("bill_pack_stock_record_v2.operation_type"); //专家购状态
    this.getStorageIdArr();
    this.getData();
  },
  mounted() {},
  activated() {},
  watch: {},
  methods: {
    // 仓库列表
    getStorageIdArr() {
      Api.getStorageList().then((res) => {
        this.storageArr = res.data.result || [];
      });
    },
    //只能输入正数和小数
    inputNumfn(e, type) {
      let num = Number(e.replace(/[^\d.]/g, ""));

      if (type == "startPrice") {
        this.addFormData.startPrice = num;
      }
      if (type == "endPrice") {
        this.addFormData.endPrice = num;
      }
      if (type == "quantity") {
        this.addFormData.quantity = num;
      }
    },

    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);
    },

    //自定义上传
    uploadSectionImg(param) {
      console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let imgName = res.data.result.fileName;
            let objs = {
              imgName: imgName,
              oldName: fileName,
            };
            this.ImgNames.push(objs);
          }
        })
        .catch((err) => {});
    },

    //删除图片
    handleRemove(file, fileListImg) {
      this.ImgNames.forEach((item, index) => {
        if (item.oldName == file.name || item.imgName == file.name) {
          this.ImgNames.splice(index, 1);
          return;
        }
      });
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.showBigImg = true;
    },
    //查看大图
    showBigimg(url) {
      if (!url) {
        return false;
      }
      this.dialogImageUrl = url; //大图
      this.showBigImg = true;
    },

    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge == false) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },
    // 获取状态展示值
    getStatusValList(status) {
      var dataArr = [];
      // 包裹状态: ==========> package_v1.package_status
      // 拒收单拒收状态: ====> bill_rejection_v1.handle_status
      // 验货单验货状态: ====> bill_inspection_v1.check_status
      // 审批状态:==========> finance_in_record_v1.approve_status
      // 核销状态:==========> finance_in_record_v1.writeoff_status
      // 单据类型:==========> finance_in_record_v1.document_status
      // 打包状态:==========> bill_pack_v1.pack_status
      // 操作类型:==========> bill_pack_stock_record_v2.operation_type
      let param = {
        tableAndFieldName: status,
      };

      Api.getStatusValList(param).then((res) => {
        // 代购状态
        if (status == "bill_pack_stock_record_v2.operation_type") {
          let dataArr = res.data.result || [];
          this.orderTypeList = dataArr;
          let obj = {
            statusVal: "",
            showName: "全部",
          };
          this.orderTypeList.unshift(obj);
        }
      });
    },

    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },
    // 上传导出模板
    uploadSectionFile(param) {
      ////console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "其他支出列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let btnListHeight = this.$refs.btnList.offsetHeight; // 按钮列表
      let pagsHeigth = 40; //分页组件
      let heardHeight = 60; //头部(黑色部分)
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + heardHeight;
      return Math.floor(windonHeight - otherHeight - 10) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      let that = this;
      let numbers = tools.getOrderNum(this.numbers); //打包批次号
      //   创建时间
      let endOperationTime = "";
      let startOperationTime = "";
      if (that.queryData.endOperationTime) {
        endOperationTime = that.queryData.endOperationTime + " " + "23:59:59";
      }
      if (that.queryData.startOperationTime) {
        startOperationTime =
          that.queryData.startOperationTime + " " + "00:00:00";
      }

      let formData = {
        billClosePackageNumber: this.queryData.billClosePackageNumber || "",
        startOperationTime: startOperationTime || "",
        endOperationTime: endOperationTime || "",
        billPackNumbers: numbers || [],
        inStorageId: this.queryData.inStorageId || "",
        outStorageId: this.queryData.outStorageId || "",
        pageStart: this.pageNum || "",
        pageTotal: this.size || "",
      };

      Api.getBillPackStockRecordV2(formData).then((res) => {
        that.className = res.data.result.className || "";
        that.tableData = res.data.result.data || [];
        that.total = res.data.result.pageCount || 0;
        this.fetTableHeight();
      });
    },
    // 查询按钮
    queryBtn_ok() {
      this.pageNum = 1;
      this.getData();
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {
        memberAgentId: "", //归属代理商id
        expertBuyNumber: "", //工单号
        dgOrderNumber: "", //代购订单号
        startOperationTime: "", //起始创建时间
        endOperationTime: "", //结束创建时间
        memberId: "", //会员号
        memberName: "", //会员名称
        expertbuyStatus: "", //状态
      };
    },
    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      ////console.log(e);
      this.currentSelectData = e || [];
    },
    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    closeDias2() {
      this.ImgNames = [];
      this.fileListImg = [];
      this.addFormData = {
        dgOrderNumber: "",
        endPrice: "",
        itemDescription: "",
        memberId: "",
        pics: [],
        quantity: "",
        startPrice: "",
        agentName: "",
        memberName: "",
      };
      this.$refs.ruleForm.resetFields();
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  // return prev + curr;
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
    // 上一页
    handlePrevPage() {
      this.pageNum = this.pageNum - 1 || 1;
      this.getData();
    },
    // 下一页
    handleNextPage() {
      this.pageNum = this.pageNum + 1 || 1;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}

.colorStyle {
  color: #f00;
}

.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}

.HeadInputbox .item_right .singleinp {
  ::v-deep {
    .el-date-editor.el-input {
      width: 100%;
    }
  }
}
</style>
